import { Platform, SubPlatformMainSectionCode } from '@/types/platform'

export const computeSubPlatform = ({
  platform,
  sectionCode,
}: {
  platform: Platform
  sectionCode?: string | null
}) => {
  if (
    platform === Platform.goodhouse &&
    SubPlatformMainSectionCode.newyear_novochag === sectionCode
  ) {
    return 'newyear_novochag'
  }

  return null
}
