import './registerServiceWorker'
import Vue from 'vue'
import {
  AppComponentsPlugin,
  SentryPlugin,
  OpenreplayPlugin,
  CKEditorPlugin,
  WebSocketPlugin,
  VueDraggablePlugin,
  NotificationsPlugin,
  VuetifyPlugin,
  PageStatusCodePlugin,
} from '@/plugins'
import { toggleAppLoader } from '@/lib/app-loader'
import { vuetify } from '@/plugins/vuetify'
import { PageStatusCodeMixin } from '@/plugins/page-status-code'
import router from './router'
import store from './store'

import { AuthGetters } from './store/modules/auth/types'
import { AxiosPlugin } from './plugins/axios'

import App from './App/App.vue'

Vue.config.productionTip = false

Vue.use(VuetifyPlugin)
  .use(AxiosPlugin)
  .use(AppComponentsPlugin)
  .use(SentryPlugin)
  .use(WebSocketPlugin)
  .use(NotificationsPlugin)
  .use(PageStatusCodePlugin)
  .use(VueDraggablePlugin)
  .use(CKEditorPlugin)
  .use(OpenreplayPlugin)

async function startApp(): Promise<Vue> {
  try {
    const isAuthenticated: AuthGetters['isAuthenticated'] =
      store.getters['auth/isAuthenticated']

    if (isAuthenticated) {
      await store.dispatch('user/fetchProfile')
    }
  } catch (error) {
    console.error(error)
  }

  return new Vue({
    mixins: [PageStatusCodeMixin],
    store,
    router,
    vuetify,
    render: (h) => h(App),
    mounted() {
      toggleAppLoader(false)
    },
  }).$mount('#app')
}

const getApp = startApp()

export { getApp }
