import { ActionListPayload, ActionListResult } from '@/types/action'
import { User } from '@/types/user'
import axios from 'axios'

export default async function fetchUsers({
  page,
  count,
  filter,
  sort,
  cancelToken,
  is_author,
  withItem,
  projectId,
}: ActionListPayload & { projectId?: number }): Promise<
  ActionListResult<User>
> {
  const headers: Record<string, string | number> = {}

  if (projectId !== undefined) {
    headers['X-Project-ID'] = projectId
  }

  const { data } = await axios.get('/api/v1/user/', {
    cancelToken,
    headers,
    params: {
      page,
      count,
      filter: filter || null,
      with: withItem,
      sort: sort || null,
      is_author,
    },
  })

  return data
}
