import colors from 'vuetify/lib/util/colors'
import Vue, { PluginObject } from 'vue'
import ru from 'vuetify/src/locale/ru'
import icons from './icons'
import Vuetify, {
  VContainer,
  VWindowItem,
  VExpandTransition,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VTabItem,
  VToolbar,
  VAppBar,
  VAppBarNavIcon,
  VFileInput,
  VBadge,
  VHover,
  VTextarea,
  VCheckbox,
  VSwitch,
  VRow,
  VCol,
  VAlert,
  VCombobox,
  VAutocomplete,
  VSkeletonLoader,
  VRadio,
  VRadioGroup,
  VDialog,
  VApp,
  VImg,
  VDataTable,
  VMain,
  VLazy,
  VTabs,
  VTab,
  VSlideGroup,
  VSlideItem,
  VWindow,
  VForm,
  VCard,
  VCardText,
  VProgressLinear,
  VIcon,
  VBtn,
  VInput,
  VTextField,
  VLabel,
  VSheet,
  VMessages,
  VList,
  VSubheader,
  VAvatar,
  VListGroup,
  VSelect,
  VChip,
  VItemGroup,
  VListItem,
  VDivider,
  VSimpleCheckbox,
  VListItemGroup,
  VTooltip,
  VTimePicker,
  VNavigationDrawer,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

export const VuetifyPlugin: PluginObject<undefined> = {
  install() {
    Vue.use(Vuetify, {
      components: {
        VContainer,
        VWindowItem,
        VExpandTransition,
        VExpansionPanelContent,
        VExpansionPanelHeader,
        VTabItem,
        VToolbar,
        VAppBar,
        VAppBarNavIcon,
        VFileInput,
        VBadge,
        VHover,
        VTextarea,
        VCheckbox,
        VSwitch,
        VRow,
        VCol,
        VAlert,
        VSkeletonLoader,
        VCombobox,
        VRadio,
        VRadioGroup,
        VAutocomplete,
        VDialog,
        VApp,
        VImg,
        VDataTable,
        VMain,
        VLazy,
        VTabs,
        VTab,
        VSlideGroup,
        VSlideItem,
        VWindow,
        VIcon,
        VBtn,
        VInput,
        VTextField,
        VForm,
        VCard,
        VCardText,
        VProgressLinear,
        VLabel,
        VSheet,
        VMessages,
        VList,
        VSubheader,
        VAvatar,
        VListGroup,
        VSelect,
        VChip,
        VItemGroup,
        VListItem,
        VDivider,
        VSimpleCheckbox,
        VListItemGroup,
        VTooltip,
        VTimePicker,
        VNavigationDrawer,
      },
      directives: {
        Ripple,
      },
    })
  },
}

const scrollBarWidth = 16

export const vuetify = new Vuetify({
  default: 'light',
  lang: { locales: { ru }, current: 'ru' },
  icons: {
    values: icons,
  },
  breakpoint: {
    thresholds: {
      md: 1280 + scrollBarWidth,
      lg: 1920 + scrollBarWidth,
    },
    scrollBarWidth,
    mobileBreakpoint: 1280,
  },
  theme: {
    themes: {
      light: {
        primary: colors.deepPurple,
        secondary: '#304156',
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,
        dark: '#242939',
        background: '#f2f3f8',
      },
    },
  },
})
