import { ActionItemResult } from '@/types/action'
import { Feature } from '@/types/feature'
import axios from 'axios'

export default async function createFeature(
  payload: Pick<Feature, 'name' | 'placement' | 'type' | 'active_from'> &
    Partial<Pick<Feature, 'target' | 'new_year'>>
): Promise<ActionItemResult<Feature>> {
  const { data } = await axios.post('/api/v1/feature-article/', payload)
  return data
}
