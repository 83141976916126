import { Getter } from 'vuex'
import { UserState } from '../types'
import { RootState } from '@/store/types'
import { Role } from '@/types/role'

const userIsGlobalAdmin: Getter<UserState, RootState> = (state) => {
  return state.current?.roles.includes(Role.ADMIN)
}

export default userIsGlobalAdmin
