import { Platform, ProjectID } from '@/types/platform'
import axios from 'axios'

export default async function addImageSupport({
  platform,
  file,
}: {
  file: FormData
  platform?: Platform | null
}): Promise<{ id: number; full_url: string }> {
  const headers: Record<string, string | number> = {}

  if (platform) {
    headers['X-Project-ID'] = ProjectID[platform]
  }

  const { data } = await axios.post(`/api/v1/support/upload/`, file, {
    headers,
    useRetry: false,
  })

  return data
}
