import { ActionItemResult, Field } from '@/types/action'
import { TechnicalArticleItem } from '@/types/technical'
import axios from 'axios'

export default async function fetchTechnicalArticle({ id }: { id: number }) {
  const { data } = await axios.get<
    ActionItemResult<TechnicalArticleItem, { special_project_id: Field[] }>
  >(`/api/v1/technical/${id}`)

  return data
}
