function getFieldName() {
  return `${
    window.location.pathname
      .split('/')
      .slice(2) // 1 for different magazines
      .join('.') || 'home'
  }.itemsPerPage`
}

export function setLocalItemsPerPage(value: number): void {
  const fieldName = getFieldName()
  window.localStorage.setItem(fieldName, value.toString())
}

export function getLocalItemsPerPage(defaultCount = 10): number {
  const fieldName = getFieldName()
  const localCount = Number(window.localStorage.getItem(fieldName))
  return localCount || defaultCount
}
