import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Issue } from '@/types/issue'

export default async function createIssue(payload: {
  name: string
  code?: string
}): Promise<ActionItemResult<Issue>> {
  const { data } = await axios.post('/api/v1/issue/', payload)
  return data
}
