import Vue, { PluginObject } from 'vue'
import axios from 'axios'
import { SIRIUS_DOMAIN } from '@/config/base'

export const WebSocketPlugin: PluginObject<undefined> = {
  async install() {
    const { default: Echo } = await import('laravel-echo')
    const { default: Pusher } = await import('pusher-js')

    window.Pusher = Pusher

    const echo = new Echo({
      namespace: window.location.search.includes('test-without-ws')
        ? 'TEST'
        : 'App\\Events\\WebSockets',
      broadcaster: 'reverb',
      key: 'websocketkey',
      // cluster: 'mt1',
      wsHost: SIRIUS_DOMAIN,
      autoConnect: true,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
    })

    axios.interceptors.request.use((config) => {
      const socketId = echo.socketId()

      if (socketId) {
        config.headers.common['X-Socket-ID'] = socketId
      }

      return config
    })

    Vue.prototype.$echo = echo
  },
}
