import { PostSupportField } from '@/types/supportFormData'
import { Platform, ProjectID } from '@/types/platform'
import axios from 'axios'

interface PostSupportParams {
  id: string
  project_id: number
  name?: string
  data: {
    fields: PostSupportField[]
    user: {
      telegram?: string
    }
  }
  files?: number[]
  platfrom?: Platform | null
}

export default async function postFormToSupport(payload: PostSupportParams) {
  return axios.post('/api/v1/support/', payload, {
    headers: payload.platfrom
      ? {
          'X-Project-ID': ProjectID[payload.platfrom],
        }
      : undefined,
  })
}
