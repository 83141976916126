var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',_vm._g(_vm._b({staticClass:"data-table",attrs:{"headers":_vm.headers,"footer-props":{
    ..._vm.$attrs.footerProps,
    itemsPerPageOptions: !_vm.hideFooter ? [10, 15, 20, 50] : [-1],
  },"page":_vm.localPage,"items-per-page":_vm.localItemsPerPage,"mobile-breakpoint":960,"hide-default-footer":""},on:{"update:page":_vm.onUpdatePage,"update:items-per-page":_vm.onUpdateItemsPerPage},scopedSlots:_vm._u([{key:`item._title`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column flex-md-row"},[_vm._t("info.avatar",null,{"item":item}),_c('div',{staticClass:"data-table__main",style:({ width: _vm.widgetSelect ? '100%' : '' })},[_vm._t("info.before",null,{"item":item}),_c('div',{class:{ 'd-flex flex-grow-1': _vm.widgetSelect },style:({ width: _vm.widgetSelect ? '100%' : '' })},[(!_vm.hideEditItem)?_c('div',{staticClass:"text-start"},[(!_vm.hiddenEditTooltipText && _vm.editTooltipText)?_c('v-tooltip',{attrs:{"nudge-right":_vm.editTooltipNudeRight,"left":"","nudge-top":"30","open-delay":"150","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"text-subtitle-2 data-table__title cursor-pointer my-1 font-weight-bold blue-grey--text text--darken-3",on:{"click":function($event){return _vm.$emit('edit:item', item)}}},[_vm._t("edit",null,{"item":item})],2)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.editTooltipText))])]):_c('div',{staticClass:"text-subtitle-2 data-table__title my-1 font-weight-bold blue-grey--text text--darken-3",on:{"click":function($event){return _vm.$emit('edit:item', item)}}},[_vm._t("edit",null,{"item":item})],2),_vm._t("edit.after",null,{"item":item})],2):_vm._e(),(_vm.$scopedSlots['view'] || _vm.$scopedSlots['view.after'])?_c('div',{class:[
              'd-flex flex-wrap align-center text-left',
              { 'flex-grow-1': _vm.widgetSelect },
            ]},[(!_vm.hideViewItem)?_c('div',{staticClass:"flex-shrink-0"},[(_vm.viewTooltipText)?_c('v-tooltip',{attrs:{"left":"","nudge-right":_vm.viewTooltipNudeRight,"nudge-top":"30","open-delay":"150","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"data-table__id font-weight-500 blue-grey--text text-caption-2 mr-3 mb-2 cursor-pointer",on:{"click":function($event){return _vm.$emit('view:item', item)}}},[_vm._t("view",null,{"item":item})],2)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.viewTooltipText))])]):_c('div',{staticClass:"data-table__id font-weight-500 blue-grey--text text-caption-2 mr-3 mb-2",on:{"click":function($event){return _vm.$emit('view:item', item)}}},[_vm._t("view",null,{"item":item})],2)],1):_vm._e(),_c('div',{class:[
                'blue-grey--text text-caption-2 mb-2 mr-3',
                { 'flex-grow-1': _vm.widgetSelect },
              ]},[_vm._t("view.after",null,{"item":item})],2)]):_vm._e()])],2)],2)]}},_vm._l((_vm.localScopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [(_vm.lastSlotName.header === slot)?_c('div',{key:slot,staticClass:"d-flex justify-end"},[_c('div',{staticClass:"flex-shrink-1 flex-grow-0"},[(_)?_vm._t(slot,null,null,scope):_c('span',[_vm._v(" "+_vm._s(scope.header.text))])],2)]):(_vm.lastSlotName.item === slot)?_c('div',{key:slot,staticClass:"d-flex justify-end"},[(_)?_c('div',{staticClass:"flex-shrink-1 flex-grow-0"},[_vm._t(slot,null,null,scope)],2):_c('div',{staticClass:"flex-shrink-1 flex-grow-0"},[_vm._v(" "+_vm._s(scope.value)+" ")])]):_vm._t(slot,null,null,scope)]}}}),{key:`item._actions`,fn:function({ item }){return [_c('v-lazy',[_c('div',{staticClass:"d-flex justify-end text-no-wrap mb-sm-0",class:{ 'data-table__actions': _vm.isWidget }},[_vm._t("actions.before",null,{"item":item}),_c('ConfirmDeleteDialog',{attrs:{"disabled":_vm.localDisabledDelete,"dialogTitle":_vm.deleteTitle(item),"settings":_vm.popUpDeleteSettings,"icon":""},on:{"confirmed:delete":function($event){return _vm.$emit('delete:item', item)}}})],2)])]}},(!_vm.hideFooter)?{key:"footer",fn:function({
      on,
      options,
      props: {
        itemsPerPageOptions,
        pagination: { itemsLength },
      },
    }){return [_c('TableFooter',{attrs:{"total-count":itemsLength,"page":_vm.localPage,"items-per-page":_vm.localItemsPerPage,"items-per-page-options":itemsPerPageOptions},on:{"update:page":_vm.onUpdatePage,"update:itemsPerPage":function($event){return on['update:options']({ ...options, itemsPerPage: $event })}}})]}}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.localListeners))
}
var staticRenderFns = []

export { render, staticRenderFns }