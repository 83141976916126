import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class BaseAutocomplete extends Vue {
  @Ref('autocomplete') private readonly autocompleteRef?: {
    focus: () => void
    setValue: <T>(value: T) => void
  }

  @Prop({ type: String }) private readonly attach?: string
  @Prop({ type: Boolean })
  private readonly autoScrollIntoViewActive!: boolean
  @Prop({ type: Number }) private readonly autoScrollIntoViewOffsetTop?: number

  private autoScrollIntoViewTimeoutId = 0

  private getScrollParentEl(node: HTMLElement | null): HTMLElement | null {
    if (!node) {
      return null
    }

    if (node.scrollHeight > node.clientHeight) {
      return node
    }

    if (node.parentElement) {
      return this.getScrollParentEl(node.parentElement)
    }

    return null
  }

  private focus() {
    this.autocompleteRef?.focus()
  }

  private setValue<T>(value: T) {
    this.autocompleteRef?.setValue(value)
  }

  private onFocus() {
    console.log('onFocus')
    this.autoScrollIntoView()
  }

  private autoScrollIntoView() {
    window.clearTimeout(this.autoScrollIntoViewTimeoutId)

    if (!this.autoScrollIntoViewActive) {
      return
    }

    const targetEl = this.$el as HTMLElement
    const scrollParentEl = this.getScrollParentEl(targetEl)

    if (!scrollParentEl) {
      return
    }

    const targetRect = targetEl.getBoundingClientRect()
    const containerRect = scrollParentEl.getBoundingClientRect()

    const autoScrollIntoViewOffsetTop = this.autoScrollIntoViewOffsetTop ?? 0

    const scrollTo =
      targetRect.top -
      containerRect.top +
      scrollParentEl.scrollTop -
      autoScrollIntoViewOffsetTop

    this.autoScrollIntoViewTimeoutId = window.setTimeout(() => {
      scrollParentEl.scrollTo({
        top: scrollTo,
        behavior: 'smooth',
      })
    }, 200)
  }

  private beforeDestroy() {
    window.clearTimeout(this.autoScrollIntoViewTimeoutId)
  }
}
