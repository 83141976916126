import stripHtmlTags from '@/helpers/stripHtmlTags'
import { ActionItemResult } from '@/types/action'
import { Article } from '@/types/article'
import axios from 'axios'

export default async function updateBrand({
  id,
  isShowNotify,
  ...payload
}: Pick<Article, 'id'> &
  Partial<Omit<Article, 'id'>> & {
    isShowNotify?: boolean
  }) {
  const { data } = await axios.put<ActionItemResult<Article>>(
    `/api/v1/brand/${id}/`,
    {
      ...payload,
      name: stripHtmlTags(payload.name),
      preview_text: stripHtmlTags(payload.preview_text),
    },
    {
      isShowNotify,
      useWS: {
        model: 'article',
        itemId: id,
      },
    }
  )

  return data
}
