export function getDefaultSort(defaultSort = 'id.desc'): string {
  return defaultSort
}

export function getSortField(sortString = ''): string {
  return sortString.split('.')[0] || ''
}

export function getSortType(sortString = ''): 'asc' | 'desc' {
  return (sortString.split('.')[1] as 'asc' | 'desc') || 'asc'
}
