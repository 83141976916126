import { PluginObject } from 'vue'
import { ENABLED_OPENREPLAY } from '@/config/base'

export const openreplayTrackerPromise = (async () => {
  if (!ENABLED_OPENREPLAY) {
    return null
  }

  const { default: Tracker } = await import('@openreplay/tracker')

  return new Tracker({
    projectKey: '4HGuipFZyM3pwMbdtrNM',
    ingestPoint: 'https://openreplay.fppressa.ru/ingest',
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputEmails: false,
    defaultInputMode: 0,
  })
})()

export const OpenreplayPlugin: PluginObject<undefined> = {
  async install() {
    const OpenreplayTracker = await openreplayTrackerPromise

    OpenreplayTracker?.start()
  },
}
