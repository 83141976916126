import { Prop, Vue, Component, Ref } from 'vue-property-decorator'
import { setLocalItemsPerPage } from '@/helpers/localItemsPerPage'
import isEmpty from '@/helpers/isEmpty'

@Component
export default class TableFooter extends Vue {
  @Ref('pageTextField') private readonly pageTextFieldRef?: {
    lazyValue: string
  }
  @Prop({ type: Number, default: 0 }) private readonly totalCount!: number
  @Prop({ type: Array, default: () => [10, 15, 20, 50] })
  private readonly itemsPerPageOptions!: number[]
  @Prop({ type: Number, default: 1 }) private readonly page!: number
  @Prop({ type: Number, default: 1 }) private readonly itemsPerPage!: number

  private get totalPage() {
    return Math.ceil(this.totalCount / this.itemsPerPage)
  }

  private get disabledPrevPage() {
    return this.page === 1
  }

  private get disabledNextPage() {
    return this.totalCount === this.endLength
  }

  private get startLength() {
    return Math.max(this.page - 1, 0) * this.itemsPerPage + 1
  }

  private get endLength() {
    return Math.min(this.startLength + this.itemsPerPage - 1, this.totalCount)
  }

  private downloadTableData() {
    this.$emit('download:table')
  }

  private onChangeItemsPerPage(value: number) {
    setLocalItemsPerPage(value)
    this.goToPage(1)
    this.$emit('update:itemsPerPage', +value)
  }

  private goToPage(val?: number | string) {
    let page = !val || isEmpty(val) || !Number.isFinite(+val) ? 1 : +val

    page = Math.min(Math.max(page, 1), this.totalPage)

    if (this.pageTextFieldRef) {
      this.pageTextFieldRef.lazyValue = page.toString()
    }

    this.$emit('update:page', page)
  }

  private goToPrevPage() {
    this.goToPage(this.page - 1)
  }

  private goToNextPage() {
    this.goToPage(this.page + 1)
  }

  private saveParamsInUrl({
    page,
    itemsPerPage,
  }: {
    page?: number
    itemsPerPage?: number
  }) {
    const query = { ...this.$route.query }

    if (page) {
      query.page = page.toString()
    }

    if (itemsPerPage) {
      query.itemsPerPage = itemsPerPage.toString()
    }

    const newFullPath = this.$router.resolve({
      query,
    }).resolved.fullPath

    if (newFullPath === this.$route.fullPath) {
      return
    }

    this.$router.replace(newFullPath)
  }

  private created() {
    const pageFromQuery = +this.$route.query.page
    const itemsPerPageFromQuery = +this.$route.query.itemsPerPage

    const page = Number.isInteger(pageFromQuery)
      ? Math.max(pageFromQuery, 1)
      : this.page

    let itemsPerPage = Number.isInteger(itemsPerPageFromQuery)
      ? itemsPerPageFromQuery
      : this.itemsPerPage

    itemsPerPage = this.itemsPerPageOptions.includes(itemsPerPage)
      ? itemsPerPage
      : this.itemsPerPageOptions[0]

    if (page !== this.page) {
      this.$emit('update:page', page)
    }

    if (itemsPerPage !== this.itemsPerPage) {
      this.$emit('update:itemsPerPage', itemsPerPage)
    }

    this.$watch(
      () => this.page,
      (page) => {
        this.saveParamsInUrl({ page })
      }
    )

    this.$watch(
      () => this.itemsPerPage,
      (itemsPerPage) => {
        this.saveParamsInUrl({ itemsPerPage })
      }
    )
  }
}
