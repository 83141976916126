import axios from 'axios'
import { ActionListPayload, ActionListResult, Field } from '@/types/action'
import { TechnicalArticleItem } from '@/types/technical'

export default async function fetchTechnicalArticles({
  filter,
  sort,
  cancelToken,
}: ActionListPayload): Promise<
  ActionListResult<TechnicalArticleItem, { special_project_id: Field[] }>
> {
  const { data } = await axios.get('/api/v1/technical/', {
    cancelToken,
    params: { filter, sort },
  })
  return data
}
