export const deleteSettingsPopup = {
  title: 'Подтверждение удаления',
  text: 'Вы действительно хотите удалить',
  btnConfirm: 'Удалить',
  btnClose: 'Отменить',
}

export const republishSettingsPopup = {
  title: 'Подтверждение отмены',
  text: 'Подтвердите отмену повторной публикации',
  btnConfirm: 'Подтвердить',
  btnClose: 'Отмена',
}

export const distributionSettingsPopup = {
  title: '',
  text: 'Вы уверены, что хотите вернуться в режим общего шаблона? Кастомные изменения не будут сохранены',
  btnConfirm: 'Уверен',
  btnClose: 'Отменить',
}

export const deleteRecipeSettingsPopup = {
  title: 'Подтверждение удаления',
  text: 'Точно удалить рецепт <strong>%itemTitle%</strong> из подборки?',
  btnConfirm: 'Подтвердить',
  btnClose: 'Отмена',
}

export const deleteRecipeIngredientSettingsPopup = {
  title: 'Подтверждение удаления',
  text: 'Точно удалить ингредиент <strong>%itemTitle%</strong> из рецепта?',
  btnConfirm: 'Подтвердить',
  btnClose: 'Отмена',
}

export const deleteRecipeStepSettingsPopup = {
  title: 'Подтверждение удаления',
  text: 'Точно удалить шаг <strong>%itemTitle%</strong> из рецепта?',
  btnConfirm: 'Подтвердить',
  btnClose: 'Отмена',
}

export const activeSettingsPopup = {
  title: 'Снять с публикации?',
  text: 'Вы действительно хотите снять с публикации',
  btnConfirm: 'Снять с публикации',
  btnClose: 'Отменить',
}

export const blockSettingsPopup = {
  title: 'Подтверждение блокировки',
  text: 'Вы действительно хотите заблокировать',
  btnConfirm: 'Заблокировать',
  btnClose: 'Отменить',
}

export const deactivateSettingsPopup = {
  title: 'Подтвреждение деактивации',
  text: 'Вы действительно хотите выключить',
  btnConfirm: 'Выключить',
  btnClose: 'Отменить',
}
