import { APP_FRONT_ENV } from '@/config/base'
import { Platform, PlatformOrigin, SubPlatformOrigin } from '@/types/platform'
import { computeSubPlatform } from './computeSubPlatform'

export const computeMatrixOrigin = ({
  platform,
  sectionCode,
}: {
  platform: Platform
  sectionCode?: string | null
}) => {
  const subPlatform = computeSubPlatform({ platform, sectionCode })
  const origin = subPlatform
    ? SubPlatformOrigin[subPlatform]
    : PlatformOrigin[platform]

  if (APP_FRONT_ENV === 'PROD') {
    return origin
  }

  return origin
    .replace('www.', '')
    .replace('://', `://${APP_FRONT_ENV.toLowerCase()}.`)
}
