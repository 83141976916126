import axios from 'axios'
import { ImageTransform } from '@/types/image'
import { ActionItemResult } from '@/types/action'

export default async function addImageTransform({
  crop_h,
  crop_w,
  crop_x,
  crop_y,
  image_id,
  settings,
  image_proportion_id,
  image_purpose_id,
  item_id,
  model_id,
  model,
  constructedImage,
  isShowNotify,
}: Partial<ImageTransform> & { isShowNotify?: boolean }): Promise<
  ActionItemResult<ImageTransform>
> {
  const { data } = await axios.post(
    `/api/v1/image-transform/`,
    {
      crop_h,
      crop_w,
      crop_x,
      crop_y,
      image_id,
      settings,
      image_proportion_id,
      image_purpose_id,
      item_id,
      model_id,
      model,
      constructedImage,
    },
    {
      useRetry: false,
      isShowNotify,
    }
  )

  return data
}
